import React from 'react'

export default function Error() {
    return (
        <div style={{
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            minHeight: '100vh',
            fontSize: '40px'
        }}>
            500
        </div>
    )
}
